import React from "react";

import Layout from "../components/layout";

const ErrorPage = () => (
    <Layout checkAuth={false} seoTitle="Error">
        <p>Unexpected Error</p>
    </Layout>
);

export default ErrorPage;
